<template>
  <b-container class="p-0">
    <div class="w-100 px-1">
      <b-button
        variant="flat-secondary"
        class="btn-icon d-lg-none text-light"
        @click="showFilter = true"
      >
        فلترة
        <unicon name="tornado" fill="#fff" />
      </b-button>
    </div>

    <div class="d-flex py-4">
      <div
        class="aside-filter pl-1 pl-lg-75"
        data-aos="fade-right"
        data-aos-duration="3000"
        :class="{ active: showFilter }"
      >
        <b-button
          variant="flat-secondary"
          class="btn-icon mb-2 d-lg-none"
          @click="showFilter = false"
        >
          <unicon name="times" fill="#fff" />
        </b-button>
        <div class="bg rounded"></div>
        <EKInputText
          class="filter-input search-input"
          fieldClass="input-field borderd "
          v-model="filter.search"
          :placeholder="t('website.searchproduct')"
          name="search"
        />
        <EKInputSelect
          :placeholder="t('website.maincat')"
          :options="categoryList"
          class="filter-input input-text filter-select"
          name="mainCategory"
          v-model="mainCat"
          :clearable="true"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              {{ $t("website.noresults") }} <em>{{ search }}</em
              >.
            </template>
            <em v-else style="opacity: 0.5">{{ $t("website.startsearch") }}</em>
          </template>
        </EKInputSelect>
        <EKInputSelect
          class="filter-input filter-select"
          :placeholder="t('website.subcat')"
          :options="subCategoryList"
          name="subCategory"
          v-model="subCat"
          :clearable="true"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              {{ $t("website.noresults") }} <em>{{ search }}</em
              >.
            </template>
            <em v-else style="opacity: 0.5">{{ $t("website.startsearch") }}</em>
          </template>
        </EKInputSelect>
        <EKInputSelect
          class="filter-input filter-select"
          :placeholder="t('website.orderby')"
          :options="sortOptionsList"
          :valueLabel="null"
          name="price"
          v-model="filter.sort"
          :clearable="true"
        >
          <template slot="option" slot-scope="{ label }">
            {{ $t("website." + label) }}
          </template>
          <template slot="selected-option" slot-scope="{ label }">
            {{ $t("website." + label) }}
          </template>
        </EKInputSelect>
        <div class="px-1 pb-1">
          <h4 class="text-white mt-3">
            <strong>{{ t("website.notfoundorder") }}</strong>
          </h4>
          <h6 class="text-white mb-3" style="opacity: 0.4">
            {{ t("website.youcancontactus") }}
          </h6>
          <b-button block to="/contactus" variant="primary">
            {{ t("website.ordernow") }}
          </b-button>
        </div>
      </div>

      <div class="content-products px-1 pl-lg-2 py-5">
        <b-col cols="12">
          <b-row v-if="filterdProducts.length">
            <b-col cols="12" md="6" v-for="(product, i) in filterdProducts" :key="i">
              <ProductCard :product="product" :i="i" />
            </b-col>
          </b-row>
          <h4 v-else class="text-white py-5 text-center">
            {{ $t("website.noproductsresult") }}
          </h4>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-center">
          <EKPagination
            class="pagination"
            :items="webProducts"
            v-model="filterdProducts"
            :pageLength="8"
            @change="scrollTop"
          />
        </b-col>
      </div>
    </div>
  </b-container>
</template>

<script>
import ProductCard from "./components/ProductCard.vue";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import EKPagination from "@Ekcore/components/EK-pagination";
import { mapActions, mapGetters, mapState } from "vuex";
import { nullGuid } from "@/EK-core/util/global";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { handleLangChange } from "@/libs/event-bus";

export default {
  components: {
    EKInputText,
    EKInputSelect,
    EKPagination,
    ProductCard,
  },
  data: () => ({
    showFilter: false,
    filterdProducts: [],
  }),
  metaInfo() {
    return {
      titleTemplate: "Awija - " + this.$t("website.allproducts"),
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {
          name: "description",
          content: "description",
        },
        {
          name: "keywords",
          content: "keywords, keywords, keywords, ...",
        },
      ],
    };
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  computed: {
    ...mapGetters(["webProducts"]),
    ...mapState({
      products: (state) => state.webProducts.products,
      filter: (state) => state.webProducts.filter,
      categoryList: (state) => state.webProducts.categoryList,
      subCategoryList: (state) => state.webProducts.subCategoryList,
      sortOptionsList: (state) => state.webProducts.sortOptionsList,
      currType: (state) => state.global.currType,
    }),
    mainCat: {
      get() {
        return this.$route.query.mainCategoryId;
      },
      set(q) {
        this.$router.push({
          name: "website products",
          query: {
            search: this.$route.query.search,
            mainCategoryId: q,
            price: this.$route.query.price,
          },
        });
        this.getWebsiteProductByCategory({
          mainCategoryId: this.mainCat ? this.mainCat : nullGuid,
          subCategoryId: this.subCat ? this.subCat : nullGuid,
        });
      },
    },
    subCat: {
      get() {
        return this.$route.query.subCategoryId;
      },
      set(q) {
        this.$router.push({
          name: "website products",
          query: {
            search: this.$route.query.search,
            mainCategoryId: this.$route.query.mainCategoryId,
            subCategoryId: q,
            price: this.$route.query.price,
          },
        });
        this.getWebsiteProductByCategory({
          mainCategoryId: this.mainCat ? this.mainCat : nullGuid,
          subCategoryId: this.subCat ? this.subCat : nullGuid,
        });
      },
    },
  },
  created() {
    this.getWebsiteProductByCategory({
      mainCategoryId: this.mainCat ? this.mainCat : nullGuid,
      subCategoryId: this.subCat ? this.subCat : nullGuid,
    });
    handleLangChange(() => {
      this.getWebsiteProductByCategory({
        mainCategoryId: this.mainCat ? this.mainCat : nullGuid,
        subCategoryId: this.subCat ? this.subCat : nullGuid,
      });
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    ...mapActions(["getWebsiteProductByCategory"]),
  },
};
</script>

<style scoped lang="scss">
.content-products {
  transition: 0.3s;
  width: calc(100% - 240px);
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
.aside-filter {
  height: 100%;
  min-height: 600px;
  max-height: 100vh;
  width: 240px;
  position: relative;
  left: 0;
  perspective: 500px;
  transform-style: preserve-3d;
  transition: 0.3s;
  z-index: 999;
  .bg {
    transition: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      104.33% 398.32% at 97.5% 92.09%,
      rgba(196, 196, 196, 0.07) 0%,
      rgba(196, 196, 196, 0) 100%
    );
    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
      inset 2px -2px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(80px);
    transform: scale(1.5, 1.4) translate3d(-2px, -12px, -152px)
      rotate3d(24, 21, 0, -10deg) skew(-1deg, -4deg);
  }
  ::v-deep label {
    padding: 0 !important;
  }
  ::v-deep .ek-form-group {
    margin-bottom: 8px;
  }
  @media screen and (max-width: 992px) {
    position: fixed;
    left: -270px;
    top: 0;
    padding-top: 1rem;
    padding-left: 1rem;
  }
  &.active {
    left: 0;
  }
  .filter-input::v-deep {
    background: radial-gradient(
      104.33% 406.54% at 97.5% 93.99%,
      rgba(196, 196, 196, 0.07) 0%,
      rgba(196, 196, 196, 0) 100%
    );
    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
      inset 2px -2px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(80px);
    .vs__dropdown-toggle,
    .input-field {
      background-color: transparent !important;
      min-height: 60px !important;
      color: #fff !important;
    }
    input {
      color: #fff;
    }
    svg {
      fill: #ccc !important;
    }
    .vs__selected {
      color: #fff;
      background: transparent;
    }
  }
}

.pagination::v-deep {
  .page-item {
    border-radius: 50% !important;
    margin: 0 5px;
    padding: 3px;
    height: 35px !important;
    width: 35px !important;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(165, 145, 145);
    background: linear-gradient(
      -180deg,
      rgba(238, 238, 238, 1) 0%,
      rgba(71, 73, 74, 1) 100%
    );
    .page-link {
      background-color: #47494a !important;
      color: #fff;
      border-radius: 5rem;
      border: 0;
      max-height: 35px !important;
      max-width: 35px !important;
    }
  }
  .page-item.active {
    height: 42px !important;
    width: 42px !important;
    padding: 2px;
    background: rgb(26, 201, 251);
    background: linear-gradient(
      180deg,
      rgba(26, 201, 251, 1) 0%,
      rgb(106, 109, 110) 100%
    );
    position: relative;
    top: -5px;

    .page-link {
      top: 0 !important;
      color: var(--custom-blue-sky) !important;
      max-height: 39px !important;
      max-width: 39px !important;
    }
  }

  .prev-item,
  .next-item {
    display: none !important;
  }
}
[dir="ltr"] {
  .aside-filter {
    .bg {
      transform: scale(1.5, 1.4) translate3d(2px, -12px, -152px)
        rotate3d(24, 21, 0, 10deg) skew(-1deg, -4deg);
    }
  }

  .arrow {
    transform: scaleX(-1);
  }
}
.search-input::v-deep {
  input {
    border-color: #d8d6de !important;
    &::placeholder {
      color: #ccc;
    }
  }
}
</style>
