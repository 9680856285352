<template>
  <div
    class="py-1 mx-auto mb-1"
    style="max-width: 400px"
    data-aos="fade-up"
    :data-aos-duration="1500 * ((i % 2) + 1)"
  >
    <div class="product-metal-border mx-auto">
      <router-link :to="'/products/' + product.id" custom>
        <div class="product-info">
          <div class="product-text">
            <h2 class="m-0 text-white mt-75">
              <strong class="d-block product-name">{{ product.name }}</strong>
              <small class="d-block py-1"> {{ t("website.ordernow") }}</small>

              <div
                class="price-range"
                v-if="
                  (product.maxCostDollar && product.minCostDollar) ||
                  (product.mainCost && product.maxCost)
                "
              >
                <strong v-if="!currType">
                  {{ product.minCost }}-{{ product.maxCost }}
                  {{ t("sp") }}
                </strong>
                <strong v-else>
                  {{ product.maxCostDollar }}
                  -
                  {{ product.minCostDollar }}
                  {{ t("usd") }}
                </strong>
              </div>

              <div v-else>
                <strong class="d-block pb-1" v-if="!currType">
                  <span>{{ product.minCost | numFormat('0,0.000') }} {{ t("sp") }}</span>
                  <template v-if="product.maxCost">
                    -
                    <span>{{ product.maxCost | numFormat('0,0.000') }} {{ t("sp") }}</span>
                  </template>
                </strong>
                <strong class="d-block pb-1" v-else>
                  <span>{{ product.minCostDollar | numFormat('0,0.000') }} {{ t("usd") }}</span>
                  <template v-if="product.MaxCostDollar">
                    -
                    <span>{{ product.MaxCostDollar | numFormat('0,0.000') }} {{ t("usd") }}</span>
                  </template>
                </strong>
              </div>

              <b-button
                variant="flat-secondary"
                class="text-custom-blue-sky"
                :to="'/products/' + product.id"
                >{{ t("website.details") }}
                <unicon name="arrow-left" class="arrow" fill="var(--custom-blue-sky)" />
              </b-button>
            </h2>
          </div>
          <img
            :src="$store.getters['app/domainHost'] + '/' + product.images[0]"
            style="object-fit: contain"
          />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
export default {
  props: ["product", "i"],
  computed: {
    ...mapState({ currType: (state) => state.global.currType }),
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
.product-metal-border {
  background: linear-gradient(0deg, #878787, #ffffff, #6d6d6d, #ffffff, #949494);
  padding: 4px;
  border-radius: 3.5rem 0.2rem 3.5rem 0.2rem;
  overflow: hidden;
  box-shadow: 0 0 20px -15px #fff;
  transition: 0.5s;
  &:hover {
    box-shadow: 0 0 20px -10px #000;
    img {
      transform: scale(1.15);
    }
  }
  .product-info {
    border-radius: 3.5rem 0.2rem 3.5rem 0.2rem;
    padding: 1rem;
    background: var(--custom-dark);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .product-text {
      h2 {
        font-size: 1rem;
        .product-name {
          font-size: 1.5rem;
        }
      }
    }
    img {
      height: 150px;
      transition: 0.5s;
    }
  }
}
</style>
